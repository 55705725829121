import React, { useContext } from "react";
import {
  QueHeader,
  QueLayout,
  QueRadioOptions,
  RadioBox,
  OurPartners,
} from "../../question_components/container";
import { FAQS } from "../../data/product-page-data/BusinessPhoneData";
import { Que1Data } from "../../question_components/data/PhoneSystem";
import { navigate } from "gatsby";
import PhoneContext from "../../contexts/phoneContext";

export default function Question1() {
  const { que1, setQue1 } = useContext(PhoneContext);

  return (
    <QueLayout FaqData={FAQS}>
      <QueHeader
        title="Let Us Find You The Best Business Phone System"
        progress={20}
        number="Question 1"
        question="Which best describes your phone system needs?"
        info="Choose one option from below."
      />
      {Que1Data.map((item) => {
        return (
          <QueRadioOptions
            three
            error={que1 === null ? true : false}
            back={item.back}
            next={que1 === null ? null : item.next}
          >
            {item.options.map((items) => {
              return (
                <RadioBox
                  key={items.answer}
                  icon={<items.icon />}
                  value={items.answer}
                  title={items.answer}
                  isSelected={que1 === items.answer}
                  onChange={(e) => {
                    setQue1(e.target.value);
                    navigate(items.to);
                  }}
                />
              );
            })}
          </QueRadioOptions>
        );
      })}
      <OurPartners />
    </QueLayout>
  );
}
